var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "asyent-window",
    {
      attrs: {
        "form-action-method": _vm.formActionWindow,
        "form-actions": _vm.formActions,
        "form-rop": _vm.rop,
        "is-form-submitted": _vm.formSubmitted,
        "page-path": _vm.pagePath,
        title: _vm.title,
        "title-icon": _vm.formActionIcon,
      },
    },
    [
      _c("asyent-form", {
        ref: _vm.formRefAE,
        attrs: {
          "data-error": _vm.dataError,
          "data-loading": _vm.dataLoading,
          errors: _vm.errors,
          "form-action-method": _vm.formActionMethod,
          "form-actions": _vm.formActions,
          "form-rop": _vm.rop,
          "is-form-submitted": _vm.formSubmitted,
          readonly: _vm.isReadonly,
          reference: _vm.formRef,
          success: _vm.success,
        },
        scopedSlots: _vm._u([
          {
            key: "default",
            fn: function () {
              return [
                _c(
                  "v-row",
                  [
                    _c(
                      "v-col",
                      { attrs: { cols: "12" } },
                      [
                        _c(
                          "asyent-card",
                          { attrs: { "no-maximize": "", title: "Header" } },
                          [
                            _c(
                              "v-row",
                              [
                                _c(
                                  "v-col",
                                  { attrs: { cols: "12", md: "4", lg: "4" } },
                                  [
                                    _c("asyent-form-display", {
                                      attrs: {
                                        value: _vm.formDataF["messageId"],
                                        label: "ID",
                                      },
                                    }),
                                  ],
                                  1
                                ),
                                _c(
                                  "v-col",
                                  { attrs: { cols: "12", md: "4", lg: "4" } },
                                  [
                                    _c("asyent-form-display", {
                                      attrs: {
                                        value: _vm.formDataF["timestamp"],
                                        label: "Timestamp",
                                      },
                                    }),
                                  ],
                                  1
                                ),
                                _c(
                                  "v-col",
                                  { attrs: { cols: "12", md: "4", lg: "4" } },
                                  [
                                    _c("asyent-form-display", {
                                      attrs: {
                                        value: _vm.formDataF["messageKey"],
                                        label: "Key",
                                      },
                                    }),
                                  ],
                                  1
                                ),
                                _c(
                                  "v-col",
                                  { attrs: { cols: "12", md: "4", lg: "4" } },
                                  [
                                    _c("asyent-form-display", {
                                      attrs: {
                                        value: _vm.formDataF["source"],
                                        label: "Source",
                                      },
                                    }),
                                  ],
                                  1
                                ),
                                _c(
                                  "v-col",
                                  { attrs: { cols: "12", md: "4", lg: "4" } },
                                  [
                                    _c("asyent-form-display", {
                                      attrs: {
                                        value: _vm.formDataF["destination"],
                                        label: "Destination",
                                      },
                                    }),
                                  ],
                                  1
                                ),
                                _c(
                                  "v-col",
                                  { attrs: { cols: "12", md: "4", lg: "4" } },
                                  [
                                    _c("asyent-form-display", {
                                      attrs: {
                                        value: _vm.formDataF["status"],
                                        label: "Status",
                                      },
                                    }),
                                  ],
                                  1
                                ),
                              ],
                              1
                            ),
                          ],
                          1
                        ),
                      ],
                      1
                    ),
                  ],
                  1
                ),
                _c(
                  "v-row",
                  [
                    _c(
                      "v-col",
                      { attrs: { cols: "12" } },
                      [
                        _c(
                          "asyent-card",
                          { attrs: { "no-maximize": "", title: "Payload" } },
                          [
                            _c(
                              "v-row",
                              [
                                _c(
                                  "v-col",
                                  { attrs: { cols: "12" } },
                                  [
                                    _c("VueJsonPretty", {
                                      attrs: {
                                        path: "res",
                                        data: _vm.formDataF["payload"],
                                      },
                                      on: { click: _vm.handleClick },
                                    }),
                                  ],
                                  1
                                ),
                              ],
                              1
                            ),
                          ],
                          1
                        ),
                      ],
                      1
                    ),
                  ],
                  1
                ),
              ]
            },
            proxy: true,
          },
        ]),
        model: {
          value: _vm.formValid,
          callback: function ($$v) {
            _vm.formValid = $$v
          },
          expression: "formValid",
        },
      }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }